import { post, patch, get } from 'api';
import { post as rawPost } from './raw';
import useSWR from 'swr';

const DEFAULT_STORE_DESCRIPTION =
  'Welcome to our store! Purchase all items before use. Details and item location will be on your email receipt. Enjoy :-)';
export function updateStore(storeId, params) {
  const url = `/services/store/${storeId}/`;
  return patch(url, params);
}

const getStoreSales = ({ storeId, status = 'COMP' }) =>
  post('/services/order/search/', {
    storeId,
    status,
  });

export const getStoreDetailsURL = (storeId) => `/services/store/${storeId}/`;

const getStoreDetails = (storeId) => get(getStoreDetailsURL(storeId));

export const useStoreDetails = (storeId) => {
  const key = getStoreDetailsURL(storeId);
  const { data, error } = useSWR(key, () => getStoreDetails(storeId));
  return {
    data: data?.data,
    isLoading: !error && !data,
    error,
  };
};

export const useStoreSales = (storeId) => {
  const key = `/services/order/search/${storeId}`;
  const { data, error } = useSWR(key, () => getStoreSales({ storeId }));
  return {
    data: data?.data,
    isLoading: !error && !data,
    error,
  };
};

export async function createStore(values) {
  const result = await post('/services/store/', {
    ...values,
    isActive: true,
  });
  return result;
}

export const duplicateStore = async ({ storeId, ...params }) => {
  const result = await post(`/services/store/copy/${storeId}/`, params);
  return result;
};

export async function createStores({ owner, stores = [] }) {
  const payload = stores.map((store) => ({
    owner,
    isActive: true,
    description: DEFAULT_STORE_DESCRIPTION,
    ...store,
  }));
  return post('/services/store/bulk/', payload);
}

export function exportOrderSales(storeId) {
  return rawPost(`/services/store/${storeId}/export/order/`);
}

export function exportProductSales(storeId) {
  return rawPost(`/services/store/${storeId}/export/orderproduct/`);
}
