import { Role } from 'api/types/auth';
import { createContext, useContext } from 'react';

export interface AuthContextUser {
  role: Role;
  id: number | null;
  name?: string;
  email?: string;
  stores?: number;
  isMasquerading?: boolean;
  hasSetPassword?: boolean;
}
export interface AuthContextValues extends AuthContextUser {
  hostLogout?: () => void;
  hostLogin?: (email: string, password: string) => void;
  guestLogin?: () => void;
  increseStores?: (count: number) => void;
}

export const defaultAuthValue = {
  role: Role.NO_AUTH,
  id: null,
  isMasquerading: false,
  increseStores: () => null,
};

export const AuthContext = createContext<AuthContextValues>(defaultAuthValue);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
