import { post, patch, del } from 'api/api';
import useSWR, { mutate } from 'swr';
import { cleanObject } from 'utils';
import { Product, ProductCategory } from './types/product';

export const productF2bDict = {
  name: 'product_name',
  zipCode: 'postal_code',
  productDelegates: 'product_delegates',
};

export async function createProduct(values: Partial<Product>) {
  const product = cleanObject(values);

  return post(
    '/services/product/',
    {
      ...product,
    },
    {
      f2bDict: productF2bDict,
    },
  );
}

export const createInHomeProduct = (payload: Product) =>
  createProduct({
    ...payload,
    category: ProductCategory.IH,
  });

export async function editProduct({ id: productId, ...values }: Partial<Product>) {
  const product = cleanObject(values);
  return patch(
    `/services/product/${productId}/`,
    {
      ...product,
    },
    {
      f2bDict: productF2bDict,
    },
  );
}

export const editInHomeProduct = (payload: Partial<Product>) =>
  editProduct({
    ...payload,
    category: ProductCategory.IH,
  });

export const getProductsList = ({ storeId, categories, ...rest }) =>
  post<Product[]>(
    '/services/product/search/',
    {
      storeId,
      categories,
      ...rest,
    },
    {
      f2bDict: productF2bDict,
    },
  );

const getLocalProductsList = ({ storeId, zipCode, country }) =>
  post(
    '/services/localproduct/search/',
    {
      storeId,
      zipCode,
      country,
      category: 'LP',
    },
    {
      f2bDict: productF2bDict,
    },
  );

export const useLocalProductsList = ({ storeId, zipCode, country }) => {
  const key = `/services/localproduct/search/${storeId}/`;
  const { data, error } = useSWR(storeId && zipCode && country ? key : null, () =>
    getLocalProductsList({ storeId, zipCode, country }),
  );
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useProductsList = ({ storeId, categories = '', ...rest }) => {
  const key = `/services/product/search/${storeId}`;
  const { data, error, mutate } = useSWR(categories ? [key, categories] : key, () =>
    getProductsList({ storeId, categories, ...rest }),
  );
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export const useHasProducts = (storeId) => {
  const key = `/hasProducts/${storeId}`;
  const { data, error } = useSWR(
    key,
    () => storeId && getProductsList({ storeId, categories: '', offset: 0, rowcount: 1 }),
  );
  return {
    data: Boolean(data?.data?.length),
    isLoading: !error && !data,
    isError: error,
  };
};

export async function copyLocalProduct({ localProductId, storeId }) {
  const result = await post(
    '/services/product/copy/',
    {
      localProductId,
      store: storeId,
      category: 'LP',
    },
    {
      f2bDict: {
        ...productF2bDict,
        localProductId: 'local_product',
      },
    },
  );

  if (result.ok) {
    mutate(`/services/product/search/${storeId}`);
    mutate(`/services/localproduct/search/${storeId}`);
  }

  return result;
}

export async function deleteProduct({ productId, storeId }) {
  const result = await del(`/services/product/${productId}/`);
  if (!result) {
    result.ok = true;
  }
  if (result?.ok) {
    mutate(`/services/product/search/${storeId}`);
  } else {
    throw new Error('Failed to delete local product');
  }

  return result;
}

export async function createProducts({ owner, products }) {
  const payload = products.map((product) => ({ owner, ...product }));
  return post('/services/product/bulk/', payload, {
    f2bDict: productF2bDict,
  });
}
