export enum ProductCategory {
  LP = 'LP', // Local product
  GG = 'GG', //
  LS = 'LS', // Loca service
  IH = 'IH',
  PO = 'PO',
  POLP = 'POLP',
}

export enum ProductSubcategory {
  AddOns = 'Add ons',
  FoodAndBeverage = 'Food and beverage',
  GoodsAndEssentials = 'Goods and essentials',
  GuestServices = 'Guest services',
  Rentals = 'Rentals',
  SomethingElse = 'Something else',
}

export type ServiceType =
  | 'LYFT'
  | 'UBER'
  | 'TURO'
  | 'INSTACART'
  | 'DRIZLY'
  | 'OPENTABLE'
  | 'RESY'
  | 'GRUBHUB'
  | 'CAVIAR';

/**
 * TODO: Once we recover data from BE it is transformed, we should not do this and just camel case keys.
 *    name: 'product_name',
 */
export interface Product {
  id: number;
  image?: string;
  productDelegates?: string[];
  dateCreated?: string;
  dateModified?: string;
  thumbnail?: string;
  originalImage?: string;
  sharedImage?: boolean;
  isArchived?: boolean;
  dateArchived?: string;
  name?: string;
  productSku?: string;
  ownerLocalProductCommission?: string;
  ownerLocalProductAmount?: string;
  category?: ProductCategory;
  description: string;
  instructions?: string;
  price?: string;
  isTaxable?: boolean;
  serviceType?: ServiceType;
  merchantName?: string;
  merchantEmail?: string;
  merchantUrl?: string;
  merchantAmount?: string;
  quantity?: number;
  alertThreshold?: number;
  preorderBy?: number;
  store: number;
  bundle?: number;
  localProduct?: number;
  merchant?: number;
  isActive?: boolean;
  isPinned: boolean;
  subcategory?: string;
  hasQuantity?: boolean;
}

export type InputProduct = Omit<
  Partial<Product>,
  'id' | 'dateCreated' | 'dateModified' | 'dateArchived'
>;
