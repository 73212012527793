export const API = {
  root: `${process.env.REACT_APP_API_ROOT}`,
};

export const keys = {
  stripe: `${process.env.REACT_APP_STRIPE_KEY_PUBLIC}`,
  paypal: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
  sentry: 'https://90834b1ae6514cd7aea0b418a8171767@o1038008.ingest.sentry.io/6007603',
};

export const env = `${process.env.REACT_APP_ENVIRONMENT}`;

export const cookies = {
  admin: `thehost_${process.env.REACT_APP_ENVIRONMENT}_auth_admin`,
  owner: `thehost_${process.env.REACT_APP_ENVIRONMENT}_auth_owner`,
  guest: `thehost_${process.env.REACT_APP_ENVIRONMENT}_auth_guest`,
  ownerrez: `thehost_${process.env.REACT_APP_ENVIRONMENT}_auth_ownerrez`,
};

export const MEDIA_QUERIES = {
  sm: '(min-width: 480px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1024px)',
  xxl: '(min-width: 1440px)',
};

export const SOCIAL_PROVIDERS = {
  'google-oauth2': 'Google',
  facebook: 'Facebook',
};

export const SOCIAL_ERROR_CODES = {
  alreadyAssociated: 'AuthAlreadyAssociated',
};
