/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { AuthContext, AuthContextUser, AuthContextValues, defaultAuthValue } from './auth';
import { executeAuthMethod, getCookieAuth } from 'utils/auth';
import {
  loginMasquerade,
  loginHost,
  logoutHost,
  createAnonymousSession,
  getCurrentOwner,
  getCurrentGuest,
} from 'api/auth';
import { Role } from 'api/types/auth';
import { Spin } from 'ui-kit';

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState<AuthContextValues>(defaultAuthValue);

  const hostLogout = useCallback(() => {
    logoutHost().then(() => {
      setAuth(defaultAuthValue);
    });
  }, []);

  const hostLogin = useCallback((email, password) => {
    return executeAuthMethod({ role: Role.HOST }, () => loginHost(email, password)).then(setAuth);
  }, []);

  const guestLogin = useCallback(() => {
    return executeAuthMethod({ role: Role.GUEST_ANONYMOUS }, createAnonymousSession).then(setAuth);
  }, []);

  const masqueradeLogin = useCallback(() => {
    return executeAuthMethod({ role: Role.HOST, isMasquerading: true }, loginMasquerade);
  }, []);

  const handleAuthMethod = useCallback((methodPromise: Promise<AuthContextUser>) => {
    methodPromise
      .then((user) => {
        setAuth(user);
        if (user?.hasSetPassword) {
          window.fbq?.(
            'trackCustom',
            'CreateAccount',
            { from: 'social', email: user?.email },
            { eventID: user?.id },
          );
        }
      })
      .catch(() => setAuth(defaultAuthValue))
      .finally(() => setLoading(false));
  }, []);

  const increseStores = useCallback((count) => {
    setAuth((prev) => ({ ...prev, stores: prev.stores + count }));
  }, []);

  useEffect(() => {
    const { role: initialRole } = getCookieAuth();

    switch (initialRole) {
      case Role.HOST:
        handleAuthMethod(executeAuthMethod({ role: Role.HOST }, getCurrentOwner));
        break;
      case Role.GUEST_ANONYMOUS:
        handleAuthMethod(executeAuthMethod({ role: Role.GUEST_ANONYMOUS }, getCurrentGuest));
        break;
      case Role.ADMIN:
        handleAuthMethod(masqueradeLogin());
        break;
      default:
        setAuth(defaultAuthValue);
        setLoading(false);
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <AuthContext.Provider value={{ ...auth, increseStores, hostLogin, hostLogout, guestLogin }}>
        {!loading && children}
      </AuthContext.Provider>
    </Spin>
  );
};

export default AuthProvider;
